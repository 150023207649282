<template>
  <getecma-container
    v-loading="isLoading"
    justify-content="start"
    direction="column"
    align-items="center"
    bg-color="theme-600"
    class="min-vh-100 light">
    <getecma-container v-if="error" color="white" bg-color="danger-dark" class="w-100 fs--md py-2">
      <small>{{ error }}</small>
    </getecma-container>
    <getecma-container v-if="info" color="white" bg-color="success-dark" class="w-100 fs--md py-2">
      <small>{{ info }}{{ info === 'the-new-password-was-sent-to' ? ` ${email}.` : ''
      }}</small>
    </getecma-container>
    <getecma-container grow="1" align-items="center">
      <getecma-form v-slot="{ validate }" :submit="submitLogin" style="width:320px">
        <getecma-row class="text-center mb--xxl">
          <img src="@/assets/images/logo-wh.png" alt="" width="200" />
        </getecma-row>
        <getecma-row class="mb--xxl">
          <getecma-input
            v-model="email"
            type="email"
            name="e-mail"
            rules="required|email"
            placeholder="E-mail"
            :focus="true" />
        </getecma-row>
        <getecma-row class="mb--xxl">
          <getecma-input
            v-model="password"
            name="senha"
            type="password"
            rules="required|min:6"
            placeholder="Senha" />
        </getecma-row>
        <getecma-row class="d-grid mb--xxl">
          <getecma-button class="p--xl" bg-color="success" :round="false" native-type="submit" @click="validate">
            Entrar
          </getecma-button>
        </getecma-row>
      </getecma-form>
    </getecma-container>
  </getecma-container>
</template>

<script>
import * as authStore from '@/modules/auth/auth.store';
import { passwordLogin } from '@/modules/auth/auth.service';
import { goToBasePage } from '@/router/route.service';
import { mixinLoading } from '@/mixins/loading/mixinLoading';

export default {
  name: 'GetecmaLoginPage',
  mixins: [mixinLoading],
  data() {
    return {
      email: '',
      password: '',
      error: null,
      info: null,
    };
  },
  beforeCreate() {
    if (authStore.getters.getToken()) {
      goToBasePage();
    }
  },
  beforeMount() {
    document.documentElement.className = 'light';
  },
  methods: {
    submitLogin() {
      this.error = null;
      this.info = null;
      this.showLoading();
      passwordLogin(this.email, this.password)
        .then(goToBasePage)
        .catch(error => {
          if (error.response.data.errors === 'USER_DELETED') {
            this.error = 'Usuário excluído!';
          } else if (error.response.data.errors === 'USER_INCORRECT') {
            this.error = 'E-mail ou senha incorreto! Por favor, tente novamente.';
          }
        })
        .finally(() => { this.hiddenLoading(); });
    },
    validateEmail(email) {
      const pattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
      return pattern.test(email);
    },
  },
};
</script>
