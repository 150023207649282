import * as authStore from '@/modules/auth/auth.store';
import * as authResource from '@/modules/auth/auth.resource';

export function passwordLogin(email, password) {
  return new Promise((resolve, reject) => authResource
    .login(email, password)
    .then(({ data }) => {
      if (!data.token) {
        reject();
      }
      authStore.mutations.setToken(data.token);
      return resolve();
    })
    .catch(err => {
      authStore.mutations.clearToken();
      return reject(err);
    }));
}
