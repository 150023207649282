export const mixinLoading = {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    isLoading() {
      return this.loading;
    },
  },
  methods: {
    showLoading() {
      this.loading = true;
    },
    hiddenLoading() {
      this.loading = false;
    },
  },
};
